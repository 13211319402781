import { useRef } from "react";
import { Form } from "@remix-run/react";

import { useTranslate } from "~/hooks/useTranslate";

import { Button, Input } from "~/components";
import { useNewsletterForm } from "~/hooks/useNewsletterForm";
import Markdown from "react-markdown";

export function NewsletterForm() {
  const { translate } = useTranslate();

  const form = useRef<HTMLFormElement>(null);

  const { submit, isSubmitting, validationErrors } = useNewsletterForm({
    form: form.current,
  });

  return (
    <div className="grid md:grid-cols-2 gap-4 md:gap-20">
      <div>
        <h2 className="md:text-[30px] md:leading-[36px] md:tracking-[0.32px]">
          {translate("newsletter.form.title")}
        </h2>
        <p className="mb-0 mt-0">{translate("newsletter.form.subtitle")}</p>
      </div>
      <Form
        onSubmit={submit}
        ref={form}
        id="newsletter-form"
        className="flex flex-col space-y-4"
      >
        <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 items-start">
          <div className="space-y-2 flex-auto w-full md:w-fit">
            <Input
              id="email"
              name="email"
              type="email"
              defaultValue=""
              placeholder={translate(
                "newsletter.form.placeholder.email"
              ).toString()}
              className="placeholder:text-newsletter-muted"
            />
            {validationErrors?.email && (
              <p className="text-destructive text-[1rem]">
                {translate(validationErrors.email)}
              </p>
            )}
          </div>
          <Button
            variant="secondary"
            disabled={isSubmitting}
            className="w-full md:w-fit"
          >
            {translate("newsletter.form.btn.signUp")}
          </Button>
        </div>
        <Markdown
          components={{
            p: ({ children }) => (
              <p className="m-0 text-[1rem] text-newsletter-muted">
                {children}
              </p>
            ),
            a: ({ children, href }) => (
              <a className="text-secondary font-medium" href={href}>
                {children}
              </a>
            ),
          }}
        >
          {translate("newsletter.form.disclaimer") as string}
        </Markdown>
      </Form>
    </div>
  );
}
