import { Link, NavLink, useLocation } from "@remix-run/react";

import { getMenuLinks } from "~/lib/utils";

import { Button } from "../ui";

import { SideNav } from "./sidenav";
import { LangToggle } from "../ui/lang-toggle";
import { Logo } from "../icons/logo";
import { useEffect, useRef, useState } from "react";
import { useTranslate } from "~/hooks/useTranslate";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

export function Header({ lang, menus }: { lang: string; menus: any }) {
  const { pathname } = useLocation();
  const { translate } = useTranslate();
  const menu = useRef<HTMLDivElement>(null);
  const [menuMode, setMenuMode] = useState<"light" | "dark">("light");

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  useEffect(() => {
    const sections: HTMLDivElement[] = gsap.utils.toArray("section");
    const menuHeight = menu.current?.offsetHeight;
    sections.forEach((section, i) => {
      const isDark = section.className.includes("menu-is-dark");
      ScrollTrigger.create({
        trigger: section,
        start: "top " + menuHeight,
        end: "bottom " + menuHeight,
        onEnter: () => setMenuMode(isDark ? "dark" : "light"),
        onEnterBack: () => setMenuMode(isDark ? "dark" : "light"),
        toggleActions: "restart none none reverse",
        immediateRender: false,
        preventOverlaps: true,
      });
    });
    return () => {
      ScrollTrigger.killAll();
    };
  }, [pathname]);

  return (
    <header ref={menu} className={`${menuMode === "dark" ? "dark" : "light"}`}>
      <div className="header_container">
        <div className="header_container__left">
          <Link className="logo" to={`/${lang}`}>
            <Logo />
          </Link>
        </div>
        <nav>
          {menus &&
            getMenuLinks(menus, "header").map(
              ({
                title,
                slug,
                navTitle,
              }: {
                title: string;
                slug: string;
                navTitle: string;
              }) => (
                <NavLink
                  key={slug}
                  to={lang + "/" + slug}
                  className={({ isActive }) => (isActive ? "active" : "")}
                  preventScrollReset={false}
                >
                  {navTitle}
                </NavLink>
              )
            )}
        </nav>
        <div className="header_container__right">
          <LangToggle lang={lang} color="foreground" />
          <Link
            to="https://outlook.office365.com/owa/calendar/OVRLAY1@ovrlay.world/bookings/"
            target="_blank"
            rel="noreferrer"
            title="OVRLAY Outlook"
          >
            <Button className="hidden xl:block">
              {translate("btn.schedule.a.call")}
            </Button>
          </Link>
          <div className="flex xl:hidden pl-2">
            <SideNav lang={lang} menus={menus} />
          </div>
        </div>
      </div>
    </header>
  );
}
